<template>
    <div class="text-container privecy container">
        <h2 class="header-title">{{ contentData.title }}</h2>
        <div class="description" v-html="contentData.content"></div>
    </div>
</template>

<script setup>
import {computed} from "vue";

const props = defineProps({
    contentData: {
        type: Object,
    },
});
let contentData = computed(() => props.contentData.data?.list?.[0] || {})
</script>


<style lang="scss" scoped>
.text-container {
    color: #182236;

    .header-title {
        font-weight: 400;
        font-size: 55px;
        line-height: 52px;
        text-transform: uppercase;
    }

    .description {
        font-weight: 400;
        font-size: 16px;
        line-height: 28px;
    }
}
</style>
<style lang="scss">
body {
    &.is-dark {
        .privecy {
            color: white !important;
        }
    }
}

</style>
